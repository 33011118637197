import { EventDispatcher } from '@utils/toolbox';

/*
		This module listens for an event containing an object and hides/shows the loading overlay
		Parameters
			show: Boolean - a value indicating whether we want to show or hide the loading overlay
			message: String - the message we want to display.
		Return value:
			None
		Example:
			EventDispatcher.addEventListener('loadingOverlay', {show: true, message: 'Sök precis innan du ska boka'})
	*/

export class LoadingOverlay {
	constructor (message) {
		this.message = message || js_params.module.LoadingOverlay.message;
		this.elements = {
			wrapper: document.querySelector('.abLoadingOverlay__loading'),
			text: document.querySelector('.abLoadingOverlay__loadingTitle')
		};
		this.elements.text.innerText = this.message;
		this.init();
	}

	init () {
		EventDispatcher.addEventListener('loadingOverlay', (value) => {
			if (value) {
				window.scroll(0, 0);
				document.body.classList.add('modal-open');
				this.elements.wrapper.style.display = 'block';
			} else {
				document.body.classList.remove('modal-open');
				this.elements.wrapper.style.display = 'none';
			}
		});
	}
}
